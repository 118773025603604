<template>
  <div class="space-y-10 smrt_stations">
    <asom-server-table
      :url="url"
      :columns="['fileName', 'dateCreated', 'error']"
    >
      <template v-slot:no="scopedSlots">
        {{ scopedSlots.rowIndex + 1 }}
      </template>
      <template v-slot:dateCreated="scopedSlots">{{displayUtcDateTime(scopedSlots.data)}}</template>
    </asom-server-table>
  </div>
</template>

<script>
import { displayUtcDateTime } from "@/helpers/dateTimeHelpers";
import { GET_SAP_SYNC_ERRORS_BY_FILE } from "@/constants/apis";

export default {
  name: "SapSyncErrorByFile",
  computed: {
    url() {
      return GET_SAP_SYNC_ERRORS_BY_FILE + "?id=" + this.$route.params.id;
    },
  },
  methods: {
    displayUtcDateTime,
  }
}
</script>
